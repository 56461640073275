import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/footer'
import '../components/styles/aboutUs.css'

const AboutUs = () => {
    const [inpVal, setInpVal] = useState("")

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 })
    }, [])

    const handleSetVal = (e) => {
        setInpVal(e.target.value)
    }

    return (
        <div className='aboutUs'>
            <Header isOnPlace={false} inpVal={inpVal} handleSetVal={handleSetVal} />
            <main className='aboutMain'>
                <div className="aboutUsInside">
                    <h1 className='textMain'>About Us</h1>
                    <p>
                        <b>Absolute Market Research</b>, headquartered in Los Angeles, California, United States, is one of the
                        leading market research and intelligence solutions provider
                        companies. Our research reports span across different industries
                        namely automotive, aerospace & defense, chemicals & materials,
                        construction & building materials, energy & power, healthcare,
                        consumer goods, electronics, semiconductors, ICT & media,
                        travel, and more. <br /><br />With the help of our domain-expert research
                        analysts, we offer thorough analysis & insights into various
                        industrial sectors. Absolute Market Research holds a dynamic position in
                        the market research industry to offer reliable insights to our
                        clients and help them in staying ahead of the competition.
                        Absolute Market Research takes into consideration various aspects of any
                        industry while drafting the syndicate as well as customized
                        study. Our research team will help you in analyzing any industry
                        from 360-degree covering different aspects such as market entry
                        strategies, futuristic opportunities, technological innovation,
                        strategic moves, existing & new market entrants, their business
                        performance, product portfolio, SWOT analysis, and more. <br /><br />Also,
                        our research reports are generated by an expert analyst team
                        that holds an industry experience of 10+ years in their industry
                        expertise. Our company excels in conducting various growth
                        advisory programs and offers knowledge services to optimize the
                        business operations of our clients and accelerate their growth
                        journey. We stand by our values which include accurate market
                        study & forecast predictions, analysis of business
                        opportunities, competitive analysis of different markets, and
                        offering precise insights backed by thorough secondary and
                        primary research with key opinion leaders of the industry. <br /><br />Our
                        robust research methodology takes into account every industry
                        aspect that can impact the growth and opportunities in the
                        market. With the help of the result-oriented team, we offer
                        extensive geographic coverage and tailor-made solutions to
                        analyze global trends such as market developments that lead to
                        transformational growth strategies. Take a look at our reports
                        that can help you in understanding how our company can add value
                        to your projects.
                    </p><br />

                    <h2>Terms & Conditions and Privacy Policy:</h2>

                    <p>
                        The research services offered by Absolute Market Research are subject to
                        terms & conditions that bind to any user that visits our website
                        or makes any purchases from us. The terms & conditions and
                        privacy policy are subject to change and are made at our sole
                        discretion. We request all the website visitors as well as
                        readers and users to go through the terms & conditions and
                        privacy policy for making informed decisions. Any reference to
                        “our website” or “the website” refers to the Absolute Market Research. <br /><br />The
                        website visitors or the users communicating with us via
                        electronic means permits us to communicate with them
                        electronically via emails or calls and update the users with
                        promotional emails, newsletters, and more. The content or
                        reports displayed or advertised on our website including text,
                        video, audio, images, and graphical representations are under
                        the sole ownership of “Absolute Market Research” and should not be copied,
                        or re-used without our prior permission. <br /><br />All the content
                        displayed on our website is protected by international copyright
                        laws and should not be reproduced, duplicated, resell, or
                        replicated without our consent. As soon as the user places an
                        order with us for any report directly via the website or through
                        a bank transfer option are obliged to pay for the same. This
                        will help us in processing and dispatching the report. Also,
                        dispatch of any order will take place post successful payment
                        completion and we guarantee to serve you till our research meets
                        your requirements. <br /><br />Our team follows the{' '}
                        <b>“No Refund” or “No Return”</b> policy post-placing the order
                        with us. Under no condition, the company will be held
                        responsible for any delays or failures arising due to
                        uncontrollable reasons or force majeure. Once the user places an
                        order with us and makes the payment, our team will process the
                        order and the report will be dispatched within 48 working hours.
                        This timeline of 48 working hours applies to the syndicate
                        reports or off-the-shelve reports present on our website. <br /><br />For
                        the customized report or custom requirements, an additional
                        timeline will be needed and the same will be conveyed to the
                        client by our sales & research team before report confirmation.
                        The report will be dispatched electronically via email to
                        support the “Go Green” initiatives. Absolute Market Research does not have
                        any control over the content published on third-party sites. Any
                        content or advertisement seen on third-party sites is not under
                        the control of Absolute Market Research. Absolute Market Research is an intelligence
                        solutions provider company and the information in the form of
                        research reports provided by us can be easily transferred to
                        other websites. <br /><br />Hence, we request users to confirm all the
                        details regarding payment terms, report delivery, timeline, and
                        costs before making payment as our company follows a{' '}
                        <b>“No Refund” or “No Return”</b> policy. <br /><br />Under special
                        circumstances, a refund might be made but it is at the sole
                        discretion of the owners of Absolute Market Research powered by FuMa. For
                        any inquiries or queries, please feel free to reach “{' '}
                        <a href='#'>sales@AbsoluteMarketResearch.com</a>
                        ”.
                    </p>
                    <br />
                    <h2>FAQs</h2>
                    <br />
                    <h4>1. What is Absolute Market Research?</h4>

                    <p>
                        <strong>Response: </strong>
                        Absolute Market Research is a one-stop solution to all your market
                        research needs. Our team of business development, as well as
                        research analysts, hold expertise of more than 8+ years in their
                        respective fields. Our research reports are designed keeping the
                        client's requirements in mind and we offer the most accurate and
                        reliable market studies with our comprehensive research
                        methodology.
                    </p>
                    <h4>
                        2. What is the delivery timeline for the report post-payment
                        confirmation?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        We deliver all the syndicate report within 48 working hours. For
                        the customized reports the delivery timeline varies based on the
                        level of customization that the client has asked for. Before
                        making the payment, our business development team conveys the
                        report delivery timeline to all our clients for both syndicate
                        as well as custom reports.
                    </p>
                    <h4>3. How often do you update your reports?</h4>
                    <p>
                        <strong>Response: </strong>
                        Our reports are updated every year. However, based on the
                        client's requirement, we do offer quarterly and half-yearly
                        report updates as well for which the charges will be different.
                    </p>
                    <h4>
                        4. What if the report I wish to procure is not present in your
                        report’s repository?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        Reports Mocha has a repository of reports across different
                        industry verticals. In case the report that our client wishes to
                        procure is not present on our website, we can take that report
                        into our production and offer it to the client on a real-time
                        basis. Thus, the client need not worry about any report that is
                        not present on our website, as we can provide any niche report
                        on an on-demand basis.
                    </p>
                    <h4>
                        5. What is the difference between single-user licenses,
                        multi-user licenses, and corporate/enterprise user licenses?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        Single-user license indicates that the report can be accessed by
                        a single user only. A multi-user license indicates that the
                        report can be accessed by a team of up to 5 users within an
                        organization. An enterprise user license indicates that the
                        report can be accessed by all the people present in an
                        organization.
                    </p>
                    <h4>6. Does your company offer post-sales support?</h4>
                    <p>
                        <strong>Response: </strong>
                        Yes, our company does offer post-sales support to resolve the
                        client queries if any, and provide them with the most accurate
                        insights. For more information regarding post-sales support,
                        please get in touch with our business development team.
                    </p>
                    <h4>
                        7. Does Absolute Market Research offer region or country-specific reports?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        Yes, our domain-expert analyst team offers region-specific as
                        well as country-specific reports based on client requirements.
                    </p>
                    <h4>8. How can I order a report and how it will be delivered?</h4>
                    <p>
                        <strong>Response: </strong>
                        The users can order the report online through our website with
                        the help of trusted payment gateways such as PayPal, RazorPay,
                        and Others. Also, we do offer a bank wire/wire transfer payment
                        option as well. In bank wire/wire transfer/bank payment, our
                        company will share an invoice with our bank details, report
                        details, and client details to assist our clients in making
                        payments via bank transfer.
                    </p>
                    <h4>
                        9. After placing the order, the report will be delivered in
                        which format?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        Post payment confirmation, the report will be delivered in PDF
                        format as well as raw data (market estimation sheet) will be
                        shared in Excel format. Also, if the client needs the report in
                        PPT format, we can share the same.
                    </p>
                    <h4>
                        10. What is the research methodology adopted by your team?
                    </h4>
                    <p>
                        <strong>Response: </strong>
                        Our team of research analysts, works on a comprehensive research
                        methodology that comprises in-depth secondary research and paid
                        primary research with key opinion leaders of the industry. Some
                        of our secondary sources include access to the company’s annual
                        reports, SEC filings, industry associations, government &
                        private organizations, exhibitions & trade shows. Also, we have
                        our in-house repository of company profiles, historic data sets
                        that we use for analyzing the market growth rate & trends. When
                        it comes to primary research our team conducts surveys,
                        face-to-face interviews, conferences, telephonic interviews, and
                        video conferences, as well as sharing questionnaires with
                        C-level executives such as R&D people, marketing managers, vice
                        presidents, CEOs, and other industry experts. Thus, with the
                        help of primary and secondary research, our market findings and
                        market size estimation are validated and precise.
                    </p>

                    <h2>Why Choose Us:</h2>

                    <ul>
                        <li>
                            <i className='ri-check-double-line' /> Our research caters to
                            all the different industry verticals such as automotive,
                            technology & media, life sciences, pharmaceuticals,
                            agriculture, biotechnology, manufacturing, machinery,
                            construction, chemicals & materials, consumer goods,
                            transportation, energy & power, BFSI, retail, and many others
                        </li>
                        <li>
                            <i className='ri-check-double-line' /> We offer syndicated as
                            well as customized reports that are curated based on client
                            requirements, the latest industry know-how, technological
                            advancements, government & private investments, competitor
                            analysis, key trends, market growth factors, and futuristic
                            analysis
                        </li>
                        <li>
                            <i className='ri-check-double-line' /> Our research team
                            conducts an in-depth study for every report by taking into
                            consideration the historic trends, present industry scenario,
                            pandemic impact, research & development, forecast analysis,
                            competitive landscape, market penetration across different
                            geographies, and more
                        </li>
                        <li>
                            <i className='ri-check-double-line' /> We have more than 5000+
                            reports in our repository to help you in analyzing all
                            different industry aspects. Also, we track all the macro and
                            microeconomic factors that will have a significant impact on
                            the industry
                        </li>
                        <li>
                            <i className='ri-check-double-line' /> We offer excellent
                            pre-sales and post-sales analyst support and our robust
                            business development team offers 24*7 assistance for resolving
                            all your queries
                        </li>
                    </ul>
                </div>
            </main>
            <Footer />
        </div >
    )
}

export default AboutUs